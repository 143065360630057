"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAppSelector = exports.useAppDispatch = exports.store = void 0;
exports.useIsAdmin = useIsAdmin;
const toolkit_1 = require("@reduxjs/toolkit");
const react_page_essentials_1 = require("@2spb/react-page-essentials");
const react_redux_1 = require("react-redux");
const react_1 = require("react");
// APP STORE
exports.store = (0, toolkit_1.configureStore)({
    reducer: {
        app: react_page_essentials_1.appState.reducer,
    },
});
const useAppDispatch = () => (0, react_redux_1.useDispatch)();
exports.useAppDispatch = useAppDispatch;
exports.useAppSelector = react_redux_1.useSelector;
function useIsAdmin() {
    const permission = (0, exports.useAppSelector)(react_page_essentials_1.selectPermission);
    return (0, react_1.useMemo)(() => permission.includes('admin'), [permission]);
}

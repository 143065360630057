"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = default_1;
const react_1 = __importStar(require("react"));
const react_components_1 = require("@grandlinex/react-components");
const react_page_essentials_1 = require("@2spb/react-page-essentials");
const react_toastify_1 = require("react-toastify");
const GlobalContext_1 = require("@/context/GlobalContext");
function default_1() {
    const t = (0, react_page_essentials_1.useTranslation)();
    const context = (0, GlobalContext_1.useGlobalContext)();
    const [data, , reload] = (0, react_components_1.useQData)(async () => {
        return (await context.listPermission()).data;
    });
    const create = (0, react_1.createRef)();
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(react_page_essentials_1.FormModal, { ref: create, title: t.get('button.add.permission'), onSubmit: async ({ form, setError }) => {
                const result = await context.createPermission({
                    permission_name: form.permission_name,
                });
                if (result.error) {
                    setError(result.error);
                }
                if (result.success) {
                    react_toastify_1.toast.success(t.get('message.success.add.permission'));
                    reload();
                }
                return result.success;
            }, config: [
                [
                    {
                        key: 'permission_name',
                        label: t.get('table.header.permission.permission_name'),
                        type: react_components_1.InputOptionType.TEXT,
                        required: true,
                    },
                ],
            ] }),
        react_1.default.createElement("h1", { className: "glx-text-center glx-w-full" }, t.get('sidebar.label.permission')),
        react_1.default.createElement(react_components_1.Grid, null,
            react_1.default.createElement(react_components_1.Button, { onClick: () => {
                    create.current?.set(true);
                }, gap: 8 },
                react_1.default.createElement(react_components_1.IOAddCircleOutline, { size: 20 }),
                " ",
                t.get('button.add.permission'))),
        data ? (react_1.default.createElement(react_components_1.Table, { rowData: data, columnDefs: [
                {
                    field: 'e_id',
                    headerName: t.get('table.header.permission.id'),
                },
                {
                    field: 'permission_name',
                    headerName: t.get('table.header.permission.permission_name'),
                },
            ] })) : (react_1.default.createElement(react_page_essentials_1.LoadingComp, null))));
}

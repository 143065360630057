"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = default_1;
const react_components_1 = require("@grandlinex/react-components");
const react_1 = __importStar(require("react"));
const react_page_essentials_1 = require("@2spb/react-page-essentials");
const react_toastify_1 = require("react-toastify");
const GlobalContext_1 = require("@/context/GlobalContext");
function default_1({ e_id, groups }) {
    const t = (0, react_page_essentials_1.useTranslation)();
    const context = (0, GlobalContext_1.useGlobalContext)();
    const [data, , reload] = (0, react_components_1.useQData)(async () => {
        return (await context.listUserGroups(e_id)).data;
    });
    const create = (0, react_1.createRef)();
    if (!data) {
        return react_1.default.createElement(react_page_essentials_1.LoadingComp, null);
    }
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(react_page_essentials_1.FormModal, { ref: create, title: t.get('button.link.group'), onSubmit: async ({ form, setError }) => {
                const result = await context.linkUserGroup({
                    user: e_id,
                    group: form.group,
                    s_start: form.s_start
                        ? new Date(form.s_start).toISOString()
                        : undefined,
                    s_end: form.s_end ? new Date(form.s_end).toISOString() : undefined,
                });
                if (result.error) {
                    setError(result.error);
                }
                if (result.success) {
                    react_toastify_1.toast.success(t.get('message.success.link.group'));
                    reload();
                }
                return result.success;
            }, config: [
                [
                    {
                        key: 'group',
                        label: t.get('table.header.group.group_name'),
                        type: react_components_1.InputOptionType.DROPDOWN,
                        items: groups.map((p) => ({
                            key: p.e_id,
                            name: p.group_name,
                        })),
                        required: true,
                    },
                ],
                [
                    {
                        key: 's_start',
                        type: react_components_1.InputOptionType.DATE_TIME,
                        label: t.get('table.header.group.start'),
                    },
                    {
                        key: 's_end',
                        type: react_components_1.InputOptionType.DATE_TIME,
                        label: t.get('table.header.group.start'),
                    },
                ],
            ] }),
        react_1.default.createElement(react_components_1.Grid, { flex: true, flexC: true, gap: 8 },
            react_1.default.createElement(react_components_1.Grid, null,
                react_1.default.createElement(react_components_1.Button, { onClick: () => {
                        create.current?.set(true);
                    }, gap: 8 },
                    react_1.default.createElement(react_components_1.IOLink, { size: 20 }),
                    " ",
                    t.get('button.link.group'))),
            react_1.default.createElement(react_components_1.Table, { rowData: data, columnDefs: [
                    {
                        field: 'map.e_id',
                        headerName: t.get('table.header.subscription.id'),
                    },
                    {
                        field: 'group.e_id',
                        headerName: t.get('table.header.group.id'),
                    },
                    {
                        field: 'group.group_name',
                        headerName: t.get('table.header.group.group_name'),
                    },
                    {
                        field: 'map.s_start',
                        headerName: t.get('table.header.group.start'),
                    },
                    {
                        field: 'map.s_end',
                        headerName: t.get('table.header.group.end'),
                    },
                ], rowAction: [
                    (row) => ({
                        onClick: async () => {
                            await context.unlinkUserGroup(row.data.map.e_id);
                            reload();
                            react_toastify_1.toast.success(t.get('message.success.unlink.group'));
                        },
                        icon: react_1.default.createElement(react_components_1.IOTrashBin, null),
                        name: t.get('button.unlink.permission'),
                    }),
                ] }))));
}
